<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ institution.id ? 'Edit' : 'New' }} host institution
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to hosts institutions list"
              @click="$router.push({name: 'admin.institutions.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Hosts institutions settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Basic information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Name</label>
                        <input
                          v-model="institution.name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Acronym</label>
                        <input
                          v-model="institution.code"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Status</label>
                        <select
                          v-model="institution.status"
                          name=""
                          class="form-select"
                        >
                          <option selected>
                            Select an option
                          </option>
                          <option value="1">
                            Active
                          </option>
                          <option value="0">
                            Inactive
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >General Phone</label>
                        <input
                          v-model="institution.general_phone"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >General phone 2</label>
                        <input
                          v-model="institution.general_phone_2"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Web site</label>
                        <input
                          v-model="institution.web"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >General email</label>
                        <input
                          v-model="institution.general_email"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Host type</label>
                        <v-select
                          v-model="institution.type"
                          label="name"
                          :options="types"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/fetchTypes')"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Private or public</label>
                        <select
                          v-model="institution.privacy"
                          name=""
                          class="form-select"
                        >
                          <option value="">
                            Select an option
                          </option>
                          <option value="1">
                            Private
                          </option>
                          <option value="0">
                            Public
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Agreement</label>
                        <select
                          v-model="institution.agreement"
                          name=""
                          class="form-select"
                        >
                          <option value="">
                            Select an option
                          </option>
                          <option value="1">
                            Yes
                          </option>
                          <option value="0">
                            No
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Catalan</label>
                        <select
                          v-model="institution.catalan"
                          name=""
                          class="form-select"
                        >
                          <option value="">
                            Select an option
                          </option>
                          <option value="1">
                            Yes
                          </option>
                          <option value="0">
                            No
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Show in web</label>
                        <select
                          v-model="institution.show_web"
                          name=""
                          class="form-select"
                        >
                          <option value="">
                            Select an option
                          </option>
                          <option value="1">
                            Yes
                          </option>
                          <option value="0">
                            No
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Web public information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Short description</label>
                        <quill-editor v-model="institution.short_description" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Long description</label>
                        <quill-editor v-model="institution.long_description" />
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                    >
                      <div class="mb-1">
                        <label class="fw-bold text-dark">Select thumbnail image to show on web public</label>
                        <div
                          class="input-group custom-file-button"
                        >
                          <label
                            class="input-group-text"
                            for=""
                          >Select File</label>
                          <input
                            id="formFileImage"
                            :ref="`file-image-1`"
                            class="form-control"
                            type="file"
                            @change="uploadFile('file-image-1')"
                          >
                        </div>
                        <img
                          v-if="institution && institution.images_web && institution.images_web.find(item => item.type == 15)"
                          class="img-fluid mt-2"
                          :src="institution.images_web.find(item => item.type == 15).url"
                        >
                      </div>
                      <small class="mt-1">
                        Files must be less than <strong>2 MB</strong>.<br>
                        Allowed file types:
                        <strong>png, gif, webp or jpg</strong>.
                      </small>
                      <p class="mt-1">
                        <a
                          class="btn bg-light-danger btn-sm"
                          @click="deleteImage(institution.images_web.find(item => item.type == 15))"
                        ><i data-feather="trash-2" /> Delete photo</a>
                      </p>
                    </div>
                    <div
                      class="col-sm-6"
                    >
                      <div class="mb-1">
                        <label class="fw-bold text-dark">Select image main to show detail institution on web public</label>
                        <div
                          class="input-group custom-file-button"
                        >
                          <label
                            class="input-group-text"
                            for=""
                          >Select File</label>
                          <input
                            id="formFileImage"
                            :ref="`file-image-2`"
                            class="form-control"
                            type="file"
                            @change="uploadFile('file-image-2')"
                          >
                        </div>
                        <img
                          v-if="institution && institution.images_web && institution.images_web.find(item => item.type == 16)"
                          class="img-fluid mt-2"
                          :src="institution.images_web.find(item => item.type == 16).url"
                        ></div>
                      <small class="mt-1">
                        Files must be less than <strong>2 MB</strong>.<br>
                        Allowed file types:
                        <strong>png, gif, webp or jpg</strong>.
                      </small>
                      <p class="mt-1">
                        <a
                          class="btn bg-light-danger btn-sm"
                          @click="deleteImage(institution.images_web.find(item => item.type == 16))"
                        ><i data-feather="trash-2" /> Delete photo</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AddFile
              v-if="institution.agreement == 1"
              :id="6"
              :title="'Agreement documents'"
              :type="6"
              :files="institution.files"
              @saveAnswer="saveAnswer($event, 'files_new')"
            />

            <div class="card"> <!-- ADDRESS -->
              <div
                v-b-toggle="`collapseAddress`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Principal address</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse :id="`collapseAddress`">
                <div class="card-content collapse show">
                  <div class="card-body">

                    <div class="row">
                      <div class="col-md-6">

                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Country</label>
                          <input
                            v-model="institution.country"
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >City</label>
                          <input
                            v-model="institution.city"
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Postal code</label>
                          <input
                            v-model="institution.postal_code"
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Address</label>
                          <input
                            v-model="institution.address"
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Rector / Head</label>
                          <v-select
                            v-model="institution.head_contact"
                            label="full_name"
                            :options="institution.contacts"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Location in map</label>
                          <vue-google-autocomplete
                            id="map"
                            :value="getFullAddress()"
                            classname="form-control"
                            placeholder="Search for a location"
                            types=""
                            @placechanged="getAddressData"
                          />
                        </div>
                        <div
                          id="institutionMap"
                          class="mt-2"
                          style="width:100%;height:500px;"
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </b-collapse>
            </div>

            <hr>

            <h2 class="mb-2">
              List of departments
              <div class="mt-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    id="publishContent"
                    v-model="technical"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="publishContent"
                  >
                    <small class="ms-1">
                      Administration
                    </small>
                  </label>
                  <input
                    id="publishContent"
                    v-model="academic"
                    type="checkbox"
                    class="form-check-input ms-2"
                  >
                  <label
                    class="form-check-label"
                    for="publishContent"
                  >
                    <small class="ms-1">
                      Academic
                    </small>
                  </label>
                </div>
              </div>
            </h2>

            <div class="row">
              <template
                v-for="department, index in institution.departments"
              >
                <div
                  v-if="!department.type && academic || department.type && technical"
                  :key="department.id"
                  class="col-4"
                >
                  <Department
                    :index="index"
                    :department="department"
                    :contacts-department="institution.contacts"
                    @saveAnswer="saveAnswer($event, 'department')"
                    @showDepartmentTransferUsers="showTransferUsers($event)"
                    @deleteDepartment="institution.departments.splice($event, 1)"
                  />

                </div>
              </template>
            </div>

            <TransferUsers
              :department-id="department_id"
              :departments="institution.departments.filter(e => e.id)"
              @removeDepartmentId="department_id = null"
            />

            <a
              class="btn btn-sm btn-dark"
              @click="addNewDepartment"
            ><i data-feather="plus" /> Add department</a>

            <hr>

            <Contact
              :id="1"
              :contacts="institution.contacts"
              :departments-insti="institution.departments"
              @saveAnswer="saveAnswer($event, 'contacts')"
            />

            <AddImage
              :title="'Images'"
              :images="institution.images"
              @saveAnswer="saveAnswer($event, 'images_new')"
            />

            <InternalNotes
              :notes="institution.notes"
              @saveNewNote="saveAnswer($event, 'notes')"
            />

            <Alias
              :id="1"
              :title="'Alias'"
              :alias="institution.alias"
              @saveAnswer="saveAnswer($event, 'alias')"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapGetters } from 'vuex'
import AddFile from '../senior-call/components/AddFile.vue'
import InternalNotes from '../../partials/components/InternalNotes.vue'
import Alias from './components/Alias.vue'
import Department from './components/Department.vue'
import AddImage from '../../partials/components/AddImage.vue'
import Contact from './components/Contact.vue'
import TransferUsers from './components/TransferUsers.vue'

export default {
  components: {
    VueGoogleAutocomplete,
    vSelect,
    AddFile,
    InternalNotes,
    Alias,
    AddImage,
    Department,
    BCollapse,
    Contact,
    TransferUsers,
  },
  data() {
    return {
      institutionId: this.$route.params.id,
      parents: {},
      sending: false,
      errors: '',
      academic: true,
      technical: true,
      department_id: null,
    }
  },
  computed: {
    ...mapGetters({
      institution: 'institutions/institution',
      types: 'institutions/types',
    }),
  },
  async mounted() {
    if (this.institutionId) {
      await this.$store.dispatch('institutions/fetchInstitution', this.institutionId)
      if (this.institution.latitude && this.institution.longitude) {
        this.changeMap(this.institution)
      }
    } else {
      await this.$store.dispatch('institutions/clear')
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.institutionId) {
          await this.$store.dispatch('institutions/update', { id: this.institutionId, data: this.institution })
        } else {
          await this.$store.dispatch('institutions/create', this.institution)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.institution.id) {
        Vue.swal('The institution has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.institutions.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    getAddressData(data) {
      this.institution.latitude = data.latitude
      this.institution.longitude = data.longitude
      this.institution.country = data.country
      this.institution.city = data.locality
      this.institution.address = data.route
      this.$forceUpdate()
      this.changeMap(this.institution)
    },
    changeMap(data) {
      // The location
      const uluru = { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }
      // The map, centered at Uluru
      const map = new google.maps.Map(
        document.getElementById('institutionMap'),
        {
          zoom: 13,
          center: uluru,
        },
      )

      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: uluru,
        map,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveAnswer(data, property) {
      if (property === 'files_new' || property === 'images_new') {
        this.institution[property] = data.data
      } else if (property === 'notes') {
        if (!this.institution[property]) {
          this.institution[property] = []
        }

        this.institution[property].push(data)
      } else {
        this.institution[property] = data
      }
    },
    addNewDepartment() {
      this.institution.departments.push({})

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    getFullAddress() {
      if (this.institution.address && this.institution.city && this.institution.country) {
        return `${this.institution.address}, ${this.institution.city}, ${this.institution.country}`
      }

      return ''
    },
    showTransferUsers(departmentId) {
      this.department_id = departmentId
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: 'Image to web site',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
      }
      switch (name) {
        case 'file-image-1':
          this.institution.card_image = fileData
          break
        case 'file-image-2':
          this.institution.detail_image = fileData
          break
        default:
          break
      }
    },
    async deleteImage(image) {
      const { institutionId } = this
      const resp = await this.$store.dispatch('institutions/deleteImage', { institutionId, imageId: image.id })

      if (resp) {
        Vue.swal('Image delete successfully', '', 'success')
      } else {
        Vue.swal('Error on delete image', '', 'error')
      }
    },
  },

}
</script>
