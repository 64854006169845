<template>
  <div class="card">
    <div
      v-b-toggle="`collapseDepartment-${index}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ department.name ? department.name : 'Department' }}</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseDepartment-${index}`"
    >
      <div class="card-content">
        <div
          class="card-body"
          style="padding-top: 0px;"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="mb-1 col-12">
                  <label
                    for=""
                    class="form-label"
                  >Type</label>
                  <br>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="department.type"
                      class="form-check-input"
                      type="radio"
                      :name="'inlineRadioOptions-' + department.id"
                      value="true"
                    >
                    <label
                      class="form-check-label"
                      for="inlineRadio1"
                    >Administration</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="department.type"
                      class="form-check-input"
                      type="radio"
                      :name="'inlineRadioOptions-' + department.id"
                      value="false"
                    >
                    <label
                      class="form-check-label"
                      for="inlineRadio2"
                    >Academic</label>
                  </div>
                </div>
                <hr>
                <template v-if="department.type != null">
                  <div class="mb-1 col-6">
                    <label
                      for=""
                      class="form-label"
                    >Name</label>
                    <v-select
                      v-if="department.type == 'true' || department.type == true"
                      v-model="department.department_type"
                      label="name"
                      :clearable="false"
                      :options="departmentTypes"
                      :get-option-key="option => option.id"
                      @input="setDepartmentType"
                    />
                    <input
                      v-if="department.type == 'false' || department.type == false"
                      v-model="department.name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="mb-1 col-6">
                    <label
                      for=""
                      class="form-label"
                    >Country</label>
                    <input
                      v-model="department.country"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="mb-1 col-6">
                    <label
                      for=""
                      class="form-label"
                    >City</label>
                    <input
                      v-model="department.city"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="mb-1 col-6">
                    <label
                      for=""
                      class="form-label"
                    >Postal code</label>
                    <input
                      v-model="department.postal_code"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="mb-1 col-12">
                    <label
                      for=""
                      class="form-label"
                    >Address</label>
                    <input
                      v-model="department.address"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <hr>
                  <h4>Head</h4>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Current head</label>
                    <v-select
                      v-model="department.chief"
                      label="full_name"
                      :clearable="false"
                      :options="contacts.filter(o => contactsDepartment.filter(e => e.full_name == o.full_name).length == 0).concat(contactsDepartment.filter(d => d.evaluator != null))"
                      :get-option-key="option => option.id"
                      @search="$store.dispatch('users/fetchChiefs', { name: $event })"
                    />
                    <p><small>Lorem ipsum</small></p>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-12">
              <a
                v-if="department.history && department.history.length > 0"
                v-b-toggle="`collapseChief-${index}`"
                class="text-dark"
              ><i data-feather="archive" /> Head history</a>
              <b-collapse
                v-if="department.history && department.history.length > 0"
                :id="`collapseChief-${index}`"
                class="mt-3"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Period</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="user, user_index in department.history">
                      <tr
                        v-if="user"
                        :key="`key-${user_index}`"
                      >
                        <td>{{ user.name }}</td>
                        <td>{{ user.from }} to {{ user.to ? user.to : 'present' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </b-collapse>
            </div>
            <div class="col-md-12">
              <hr>
              <a
                class="text-danger"
                @click="deleteDepartment(department)"
              ><i data-feather="trash-2" /> Delete department</a>
              <a
                class="text-dark ms-2"
                @click="toggleMap"
              ><i data-feather="eye" /> {{ showMap ? 'Hide' : 'Show' }} map</a>
              <a
                v-if="department.id"
                class="text-dark ms-2"
                @click="showTransferUsers"
              ><i data-feather="users" /> Transfer users</a>
            </div>
            <div
              v-if="showMap"
              class="col-md-12 mt-1"
              style="height: 350px;"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Location in map</label>
                <vue-google-autocomplete
                  :id="`map-${index}`"
                  :value="getFullAddress()"
                  classname="form-control"
                  placeholder="Search for a location"
                  types=""
                  @placechanged="getAddressData($event)"
                />
              </div>
              <div
                :id="`departmentMap-${index}`"
                class="mt-2"
                style="width:100%;height:80%;"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BCollapse,
    VueGoogleAutocomplete,
    vSelect,
  },
  props: {
    department: { type: Object, required: true },
    contactsDepartment: { type: Array, required: false, default: () => [] },
    index: { type: Number, required: true },
  },
  data() {
    return {
      name: null,
      showMap: false,
    }
  },
  computed: {
    ...mapGetters({
      contacts: 'users/contacts',
      departmentTypes: 'departmentTypes/items',
    }),
  },
  async mounted() {
    if (this.department.latitude && this.department.longitude) {
      this.changeMap(this.department)
    }

    if (this.departmentTypes.length === 0) {
      await this.$store.dispatch('departmentTypes/fetch')
    }
  },
  methods: {
    getAddressData(data) {
      this.department.latitude = data.latitude
      this.department.longitude = data.longitude
      this.department.country = data.country
      this.department.city = data.locality
      this.department.address = data.route
      this.$forceUpdate()
      this.changeMap(this.department)
    },
    changeMap(data) {
      // The location
      const uluru = { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }
      // The map, centered at Uluru
      const map = new google.maps.Map(
        document.getElementById(`departmentMap-${this.index}`),
        {
          zoom: 13,
          center: uluru,
        },
      )

      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: uluru,
        map,
      })
    },
    getFullAddress() {
      if (this.department.address && this.department.city && this.department.country) {
        return `${this.department.address}, ${this.department.city}, ${this.department.country}`
      }

      return ''
    },
    toggleMap() {
      this.showMap = !this.showMap

      setTimeout(() => {
        if (this.showMap) {
          this.changeMap(this.department)
        }
      }, 500)
    },
    deleteDepartment(department) {
      if (department.id) {
        Vue.swal({
          title: 'Delete the department?',
          text: "Are you sure? You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete!',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('institutions/deleteDepartment', department.id).then(() => {
              this.$toastr.success('', 'Department deleted successfully!')
            })
          }
        })
      } else {
        this.$emit('deleteDepartment', this.index)
      }
    },
    setDepartmentType(event) {
      this.department.name = event.name
      this.department.institution_department_type_id = event.id
    },
    showTransferUsers() {
      this.$emit('showDepartmentTransferUsers', this.department.id)
    },
  },
}
</script>
